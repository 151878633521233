import { InitialState } from 'features/taskManager/index.interface';

export const I18N_PATH = 'task_manager';
export const TOOLBAR_I18N_PATH = `${I18N_PATH}.toolbar`;
export const HERO_I18N_PATH = `${I18N_PATH}.hero`;
export const DETAILS_I18N_PATH = `${I18N_PATH}.details`;
export const TASK_MANAGER_SLICE_NAME = 'TASK_MANAGER';

export const INITIAL_STATE: InitialState = {
  hasPurchasedTaskManager: false,
};
