import React, {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import withCx, { CxProps } from 'fe-core/util/withCx';
import Box from 'fe-design-base/atoms/Box';
import Checkbox from 'fe-design-base/atoms/Checkbox';
import Icon, { IconName } from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';
import { BaseInputProps, InputChangeEvent } from 'fe-design-base/baseTypes';
import Divider from 'fe-design-base/molecules/Divider';
import colors from 'fe-design-base/styles/colors';

import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

import { ApplySection } from './ApplySection';
import {
  isOptionType,
  shouldShowApplySection,
  shouldShowClearIcon,
} from './helpers';
import { SelectInputValue } from './SelectInputValue';
import { getSelectInputStyles } from './styles';

interface CustomSelectElement {
  node: HTMLElement;
}

export type OptionType = {
  label: string;
  subLabel?: string;
  value: string | number;
  icon?: IconName;
  colorDot?: string;
  disabled?: boolean;
};

export type DividerType = {
  divider: true;
};

export type GroupLabelType = {
  groupLabel: string;
  groupSubLabel?: string;
};

export type SelectOptionsType = (OptionType | DividerType | GroupLabelType)[];

export interface SelectProps extends BaseInputProps {
  options: SelectOptionsType;
  id?: string;
  shrink?: boolean;
  multiple?: boolean;
  menuMaxHeight?: number;
  uxElement?: string;
  label?: string | ReactNode;
  error?: boolean;
  placeholder?: string;
  displayAsText?: boolean;
  startIcon?: IconName;
  showApplyActions?: boolean;
  onApply?: (value: []) => void;
  onCancel?: (value: []) => void;
  onClose?: () => void;
  clearIcon?: boolean;
}

const Select = forwardRef(
  (
    {
      id,
      dataTestId,
      name,
      value = '',
      onChange,
      disabled,
      multiple,
      options,
      tabIndex,
      menuMaxHeight,
      uxElement,
      label,
      placeholder,
      cx,
      cxEl,
      error,
      displayAsText,
      startIcon,
      showApplyActions,
      onApply,
      onCancel,
      onClose,
      clearIcon,
    }: SelectProps & CxProps,
    ref: ForwardedRef<any>
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [maxWidth, setMaxWidth] = useState<number | undefined>(undefined);
    const [initialValues, setInitialValues] = useState(value);
    const [changesWereApplied, setChangesWereApplied] = useState(false);
    const selectRef = useRef<CustomSelectElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const showApplySection = shouldShowApplySection({
      showApplyActions,
      multiple,
      displayAsText,
    });

    const showClearIcon = shouldShowClearIcon({
      value,
      showApplyActions,
      clearIcon,
      disabled,
    });

    useEffect(() => {
      const selectElementWidth = selectRef?.current?.node?.clientWidth;
      const widthAdjust = showClearIcon ? 70 : 50;

      if (selectElementWidth) {
        setMaxWidth(selectElementWidth - widthAdjust);
      }
    }, [showClearIcon]);

    useEffect(() => {
      if (showApplySection && changesWereApplied) {
        setInitialValues(value);
        setChangesWereApplied(false);
      }
    }, [changesWereApplied, showApplySection, value]);

    const handleOpen = useCallback(
      e => {
        if (!isOpen && !disabled) {
          // This will allow for the select menu to open unless its the close x on the chip
          if (!e.target.className.includes('FDBChipClosable__button'))
            setIsOpen(true);
        }
      },
      [disabled, isOpen]
    );

    const handleCancel = useCallback(() => {
      onCancel?.(initialValues as []);
      setIsOpen(false);
    }, [initialValues, onCancel]);

    const handleClose = useCallback(() => {
      if (showApplySection) handleCancel();
      else {
        setIsOpen(false);
        onClose?.();
      }
    }, [handleCancel, onClose, showApplySection]);

    const selectSx = useMemo(
      () => getSelectInputStyles({ disabled }),
      [disabled]
    );

    const trackUx = useTrackUx(
      useMemo(
        () => ({
          element: uxElement,
          field: label || name,
        }),
        [label, name, uxElement]
      ) as any
    );

    const handleMenuItemClick = useCallback(
      e => {
        if (uxElement)
          trackUx(EVENT_ACTIONS.DROPDOWN_CLICKED, TRACK_ACTION_TYPES.CLICK, {
            dropdownSelection: e.target.textContent,
          });
        if (!multiple && !showApplySection) {
          e.stopPropagation();
          handleClose();
        }
      },
      [handleClose, multiple, showApplySection, trackUx, uxElement]
    );

    const handleMultiValueRemove = useCallback(
      (option: string | number) => {
        if (value && Array.isArray(value)) {
          const newValue = value.filter(item => item !== option);

          onChange?.({
            target: {
              name,
              value: newValue,
            },
          } as InputChangeEvent);
        }
      },
      [name, onChange, value]
    );

    const renderInputValue = useCallback(
      (selected: string | number | readonly (string | number)[]) => (
        <SelectInputValue
          selected={selected}
          placeholder={placeholder}
          startIcon={startIcon}
          options={options}
          multiple={multiple}
          displayAsText={displayAsText}
          maxWidth={maxWidth}
          disabled={disabled}
          onMultiValueRemove={handleMultiValueRemove}
          cxEl={cxEl}
        />
      ),
      [
        placeholder,
        startIcon,
        options,
        multiple,
        displayAsText,
        maxWidth,
        disabled,
        handleMultiValueRemove,
        cxEl,
      ]
    );

    const handleOnApplyChange = useCallback(() => {
      onApply?.(value as []);
      setIsOpen(false);
      setChangesWereApplied(true);
    }, [onApply, value]);

    const handleClearClick = useCallback(
      e => {
        e.stopPropagation();
        onChange?.({
          target: {
            name,
            value: multiple ? [] : '',
          },
        } as InputChangeEvent);
      },
      [multiple, name, onChange]
    );

    const handleKeyDown = useCallback(e => {
      // if last item in the drop down, ArrowDown to  apply/cancel buttons
      if (e.target.className.includes('isLast') && e.key === 'ArrowDown') {
        buttonRef?.current?.focus();
      }
    }, []);

    return (
      <MuiSelect
        data-testid={dataTestId}
        inputRef={selectRef}
        open={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        onClick={handleOpen}
        name={name}
        id={id}
        className={cx({ error })}
        value={value}
        aria-disabled={disabled}
        multiple={multiple}
        endAdornment={
          showClearIcon && (
            <Icon
              className={cxEl('clear-icon')}
              dataTestId="FDBSelect-clear-icon"
              iconName="Clear"
              color="purple500"
              onClick={handleClearClick}
            />
          )
        }
        sx={selectSx}
        onChange={onChange}
        renderValue={renderInputValue}
        placeholder={placeholder}
        inputProps={{
          style: {
            color: disabled ? colors.mono500 : colors.mono900,
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: menuMaxHeight
              ? {
                  maxHeight: `${menuMaxHeight}px`,
                }
              : undefined,
          },
        }}
        tabIndex={tabIndex}
        displayEmpty
        ref={ref}
      >
        {options?.map((option, index) => {
          const isSelected =
            isOptionType(option) &&
            (Array.isArray(value)
              ? value.includes(option.value)
              : value === option.value);

          const isLast = index === options.length - 1;

          if ('divider' in option) {
            return option.divider ? (
              <Divider spacing={4} key={`divider-${index}`} />
            ) : null;
          }

          if ('groupLabel' in option) {
            return option.groupLabel ? (
              <Box
                p={12}
                mt={3}
                mb={3}
                key={option.groupLabel}
                className={cxEl('group-label')}
              >
                <Text variant="bodyBold" color="mono700">
                  {option.groupLabel}
                </Text>
                <Box mt={4}>
                  <Text variant="bodySmTight" color="mono700">
                    {option.groupSubLabel}
                  </Text>
                </Box>
              </Box>
            ) : null;
          }

          return (
            <MenuItem
              className={cxEl('menu-item', {
                isSelected,
                isLast,
                hasSubLabel: option.subLabel,
              })}
              key={option.value}
              onKeyDown={handleKeyDown}
              value={option.value}
              onClick={handleMenuItemClick}
              disabled={option.disabled}
            >
              {!multiple && option.icon && (
                <Icon
                  size="medium"
                  iconName={option.icon}
                  mr={8}
                  color={disabled ? 'mono500' : 'mono700'}
                />
              )}
              {!multiple && !option.icon && option.colorDot && (
                <div
                  className={cxEl('color-dot')}
                  style={{
                    backgroundColor: option.disabled
                      ? colors.mono500
                      : option.colorDot,
                    marginRight: '8px',
                    minWidth: '20px',
                    minHeight: '20px',
                    borderRadius: '50%',
                    marginTop: option.subLabel ? '1px' : undefined,
                  }}
                />
              )}
              {multiple && (
                <Box
                  dataTestId={option.label}
                  mt={option.subLabel ? '-5px' : undefined}
                >
                  <Checkbox
                    name={option.label}
                    checked={isSelected}
                    disabled={option.disabled}
                  />
                </Box>
              )}
              <Box column>
                <Text variant="bodyTight">{option.label}</Text>
                {option.subLabel && (
                  <Box mt={4}>
                    <Text
                      variant="bodySmTight"
                      color={option.disabled ? 'mono500' : 'mono700'}
                    >
                      {option.subLabel}
                    </Text>
                  </Box>
                )}
              </Box>
              {isSelected && !multiple && (
                <Box ml="auto">
                  <Icon
                    size="medium"
                    iconName="Success"
                    color="purple500"
                    ml={8}
                    mr={2}
                  />
                </Box>
              )}
            </MenuItem>
          );
        }) ?? null}
        {showApplySection && (
          <ApplySection
            onApply={handleOnApplyChange}
            onCancel={handleCancel}
            buttonRef={buttonRef}
            uxElement={uxElement}
          />
        )}
      </MuiSelect>
    );
  }
);

export default withCx<SelectProps>('FDBSelect')(Select);
